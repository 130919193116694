<template>
  <div
    v-if="isActive && blok.content?.length"
    ref="$alert"
    class="bm-alert"
    :class="{ 'chatbase-visible': floatingElementsState.chatbaseVisible }">
    <div
      v-editable="blok"
      :data-cursor="
        blok.content[0].component === 'bm_alert-content-media' &&
        blok.content[0].button?.length &&
        isActive &&
        cursorVisible
          ? JSON.stringify({
              cursor: 'pointer',
              button: getCursorButton(),
              click: true,
            })
          : undefined
      "
      class="wrapper"
      :class="[blok.is_dark ? 'is-dark' : 'is-light', isMedia ? 'is-media' : 'is-text']">
      <!-- Media -->
      <div v-if="blok.content[0]?.component === 'bm_alert-content-media'" class="media">
        <UtilMediaResponsive
          v-if="!isMediaVideo || (isMediaVideo && isMediaWebm)"
          :image="blok.content[0].media"
          :imageMobile="blok.content[0].media"
          :video="blok.content[0].media"
          :autoplay="true" />
      </div>

      <div class="body">
        <!-- Texts -->
        <div class="content">
          <h4 class="headline">
            {{ blok.headline }}
          </h4>
          <p v-if="blok.content[0].component === 'bm_alert-content-text' && blok.content[0].text" class="text">
            {{ blok.content[0].text }}
          </p>
        </div>

        <!-- Links -->
        <UtilLinkItemIcon
          v-if="
            blok.content[0].component === 'bm_alert-content-text' &&
            blok.content[0].link?.[0] &&
            blok.content[0].link[0].component === 'util_link-item-icon' &&
            !cursorVisible
          "
          :blok="blok.content[0].link[0]"
          :isDark="blok.is_dark"
          class="link" />

        <!-- Mobile Button -->
        <UtilButton
          v-else-if="
            blok.content[0].component === 'bm_alert-content-media' && blok.content[0].button?.[0] && !cursorVisible
          "
          :blok="blok.content[0].button[0]"
          :isSmallOnMobile="true"
          class="button" />
      </div>

      <!-- Close icon -->
      <div class="close" data-cursor="" @click="toggleAlert(false)">
        <BaseIcon name="fi_x" :size="16" color="inherit" />
      </div>
    </div>

    <!-- Mouse follow -->
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap';
import { useState } from 'nuxt/app';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue';
import { BaseIcon } from '~/components/base';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilLinkItemIcon from '~/components/storyblok/utils/UtilLinkItemIcon/UtilLinkItemIcon.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import { useFloatingElements } from '~/composables/useFloatingElements';
import { useGsap } from '~/composables/useGsap';
import { useHeader } from '~/composables/useHeader';
import { useScroll } from '~/composables/useScroll';
import { isVideo, isVideoWebm } from '~/utils/media';
import type { BmAlertStoryblok } from '~/types/storyblok-generated';

const props = defineProps<{
  blok: BmAlertStoryblok;
}>();

const { state: scrollState } = useScroll();
const { state: headerState } = useHeader();
const { state: floatingElementsState } = useFloatingElements();
const { easeOut } = useGsap();
// Global cursor state (breakpoint & touch)
const cursorVisible = useState('cursor');

const isActive = ref<boolean>(true);
const windowWidth = ref(0);
const $alert = ref<HTMLElement>();
const isMedia = computed(() => props.blok.content?.[0]?.component === 'bm_alert-content-media');
const isMediaVideo = computed(
  () =>
    props.blok.content?.[0]?.component === 'bm_alert-content-media' && isVideo(props.blok.content?.[0]?.media?.filename)
);
const isMediaWebm = computed(
  () =>
    props.blok.content?.[0]?.component === 'bm_alert-content-media' &&
    isVideoWebm(props.blok.content?.[0]?.media?.filename)
);
let hideTimeout: ReturnType<typeof setTimeout>;

function updateWindowWidth() {
  if (import.meta.client) {
    windowWidth.value = window.innerWidth;
  }
}

function getCursorButton() {
  return props.blok.content[0].component === 'bm_alert-content-media' ? props.blok.content[0].button?.[0] : undefined;
}

const toggleAlert = async (shouldShow: boolean) => {
  if (!shouldShow) {
    document.body.style.cursor = 'auto';
  }

  floatingElementsState.alertVisible = shouldShow;

  if (shouldShow) {
    isActive.value = true;
    await nextTick();
  }

  gsap.to($alert.value as HTMLElement, {
    opacity: shouldShow ? 1 : 0,
    y: shouldShow ? 0 : 16,
    duration: 0.3,
    ease: easeOut,
    onComplete: () => {
      isActive.value = shouldShow;
    },
  });
};

// Close alert on click, or after a certain amount of time
function setAlertClosing() {
  const hideAfterSeconds = parseInt(props.blok.hide_after_seconds ?? '0');
  if (hideAfterSeconds) {
    clearTimeout(hideTimeout);
    hideTimeout = setTimeout(() => {
      void toggleAlert(false);
    }, hideAfterSeconds * 1000);
  }
}

function setAlertBehaviour() {
  void toggleAlert(true);
  setAlertClosing();
}

function updateAlertMargin() {
  if (!$alert.value) {
    return;
  }

  const MAX_WIDTH = 1920;

  if (windowWidth.value > MAX_WIDTH) {
    const marginRight = (windowWidth.value - MAX_WIDTH) / 2;

    gsap.to($alert.value, {
      marginRight: `${marginRight}px`,
      duration: 0,
    });
  } else {
    gsap.to($alert.value, {
      marginRight: 0,
      duration: 0,
    });
  }
}

watch(
  () => headerState.menuOpen,
  (value: boolean) => {
    if (!$alert.value) {
      return;
    }
    if (value) {
      $alert.value.classList.add('hidden');
    } else {
      $alert.value.classList.remove('hidden');
    }
  }
);

watchEffect(() => {
  if (import.meta.client) {
    updateAlertMargin();
  }
});

watch(
  () => scrollState.activeModule,
  value => {
    if (value === `module-${props.blok.appear_at_module}`) {
      setAlertBehaviour();
    }
  }
);

onMounted(() => {
  // If the alert appear at module is not set, show the alert immediately
  if (props.blok.appear_at_module && !parseInt(props.blok.appear_at_module)) {
    setAlertBehaviour();
  } else {
    isActive.value = false;
  }

  if (import.meta.client) {
    updateWindowWidth();
    updateAlertMargin();
    window.addEventListener('resize', updateWindowWidth);
  }
});

onUnmounted(() => {
  clearTimeout(hideTimeout);
  window.removeEventListener('resize', updateWindowWidth);
});
</script>

<style src="./BMAlert.scss" lang="scss" scoped />
