<template>
  <div v-if="storyRes.story && !!settings" :class="storyRes.story.content.is_dark ? 'has-black-bg' : 'has-white-bg'">
    <a class="skip-to-content-link" href="#main">{{ t('global.skipToContent') }}</a>
    <BMHeaderRich
      v-if="typeof header !== 'string' && header?.content"
      :blok="header.content"
      :inPageBlok="storyRes.story.content.header_in_page"
      :dataProduct="
        (typeof storyRes.story.content.data_product !== 'string' && storyRes.story.content.data_product) || undefined
      "
      :alternates="storyRes.story.alternates" />
    <CorePage
      :blok="storyRes.story.content"
      :settings="settings.content"
      :footerBlok="
        typeof settings.content.footer_default !== 'string' ? settings.content.footer_default?.content : undefined
      " />
    <BMAlert v-if="alert" :blok="alert" />
    <BMFooter
      v-if="typeof settings.content.footer_default !== 'string' && settings.content.footer_default?.content"
      :blok="settings.content.footer_default.content"
      :isDark="storyRes.story.content.is_dark" />

    <!-- Global cursor overlay -->
    <BaseCursor />

    <!-- Chatbase chatbot -->
    <BaseChatbase />

    <!-- Video modal -->
    <BaseModalVideo :youtubePrivacyWarning="settings.content.youtube_privacy_warning" />

    <!-- Shopping cart -->
    <BMShoppingCart
      v-if="
        typeof settings.content.shopping_cart !== 'string' &&
        settings.content.shopping_cart?.content &&
        typeof header !== 'string' &&
        header?.content
      "
      :blok="settings.content.shopping_cart.content"
      :headerBlok="header?.content" />

    <!-- These are all layers called from the page, it's needed to render for SEO -->
    <CorePageLayer
      v-for="(layer, index) in storyRes.pageLayers"
      :key="index"
      :blok="layer"
      :settings="settings.content" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#imports';
import { useStoryblokBridge } from '@storyblok/vue';
import { useHead, useRoute } from 'nuxt/app';
import { computed, onMounted, watch } from 'vue';
import { BaseChatbase, BaseCursor, BaseModalVideo } from '~/components/base';
import BMAlert from '~/components/storyblok/basic/BMAlert/BMAlert.vue';
import BMFooter from '~/components/storyblok/basic/BMFooter/BMFooter.vue';
import BMHeaderRich from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRich.vue';
import BMShoppingCart from '~/components/storyblok/basic/BMShoppingCart/BMShoppingCart.vue';
import CorePage from '~/components/storyblok/core/CorePage/CorePage.vue';
import CorePageLayer from '~/components/storyblok/core/CorePageLayer/CorePageLayer.vue';
import { useFooter } from '~/composables/useFooter';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useHeader } from '~/composables/useHeader';
import { useLocale } from '~/composables/useLocale';
import { defaultStoryParams, useStoryblokClient } from '~/composables/useStoryblokClient';
import { useTheme } from '~/composables/useTheme';
import { moduleSettingsMap } from '~/utils/module-settings';
import { createSeoMetaTags } from '~/utils/seo-meta';
import type { CorePageStoryblok } from '~/types/storyblok-generated';

const { locale } = useLocale();
const { t } = useI18n();
const { state: headerState } = useHeader();
const { state: footerState } = useFooter();
const route = useRoute();
const { getSettings, getStory } = await useStoryblokClient();
const settings = await getSettings(locale);
const storyRes = await getStory<CorePageStoryblok>(route.path);

onMounted(() => {
  useStoryblokBridge(storyRes.value?.story?.id, newStory => (storyRes.value.story = newStory), defaultStoryParams);
});

/**
 * Initialize GTM tracking and translations hooks
 */
const { trackPageViews } = useGtmTracking();
trackPageViews(storyRes);

/**
 * Get relevant header and alert from story or settings
 */
const alert = computed(() => {
  return storyRes.value.story.content.alert?.[0] || settings.value.content.alert?.[0];
});
const header = computed(() => {
  if (storyRes.value.story.content.header_alternative?.length) {
    return storyRes.value.story.content.header_alternative[0];
  }
  return settings.value.content.header_rich_default;
});

/**
 * Set dark mode by the first component. Used for the theme of the header.
 */
if (storyRes.value.story && (storyRes.value.story.content.body?.length ?? 0) > 0) {
  const { setIsDarkByComponent } = useTheme();
  const firstBlok = storyRes.value.story.content.body?.[0];
  if (firstBlok) {
    setIsDarkByComponent(firstBlok);
  }
}

/**
 * Set header state
 */
function updateHeaderState() {
  // Prevent header sliding when standalone modules are present
  const storyHasStandaloneModules = storyRes.value.story.content.body?.some(
    blok => moduleSettingsMap[blok.component]?.standalone
  );
  headerState.preventSlide = !!storyHasStandaloneModules;
}
updateHeaderState();
watch(storyRes, updateHeaderState);
watch(settings, updateHeaderState);

/**
 * Set the footer state
 */
function setFooterState() {
  const hasHiddenFooter = storyRes.value.story.content.body?.some(
    blok => moduleSettingsMap[blok.component]?.hideFooter
  );
  footerState.hidden = !!hasHiddenFooter;
}
watch(storyRes, setFooterState);
setFooterState();

/**
 * Add SEO meta tags
 */
useHead({ ...createSeoMetaTags(locale, storyRes.value.story) });
</script>

<style lang="scss" scoped>
@use '~/assets/scss/variables';

.has-black-bg {
  background-color: variables.$black;
}

.has-white-bg {
  background-color: variables.$white;
}

.skip-to-content-link {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  padding: 7px 14px;
  margin: 4px;
  background: variables.$brown;
  border-radius: variables.$border-radius-xs;
  color: variables.$white !important;
  transform: translateY(-200px);

  &:focus-visible {
    transform: translateY(0);
  }
}
</style>
