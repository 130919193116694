<template>
  <div ref="$el" class="mouse-follow" :class="{ 'mouse-follow--collapsed': !hideMouse }">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useMouseFollow } from '~/composables/useMouseFollow';

const $el = ref<HTMLElement>();
const { state } = useMouseFollow();

const props = defineProps({
  shouldBeEnabled: {
    type: Boolean,
    default: false,
  },
  hideMouse: {
    type: Boolean,
    default: true,
  },
  shouldFollow: {
    type: Boolean,
    default: true,
  },
  instantFollow: {
    type: Boolean,
    default: false,
  },
});

const isMouseEnabled = ref(props.shouldBeEnabled);

const tickerPosition = { x: 0, y: 0 }; //SPAWN POSITION?
const onTicker = () => {
  if (!$el.value) {
    return;
  }

  const speed = props.instantFollow ? 1 : 10;

  tickerPosition.x -= (tickerPosition.x - state.position.x) / speed;
  tickerPosition.y -= (tickerPosition.y - state.position.y) / speed;

  const xOffset = tickerPosition.x + $el.value.offsetWidth > window.innerWidth - 20 ? -$el.value.offsetWidth - 10 : 10;

  const yOffset =
    tickerPosition.y + $el.value.offsetHeight > window.innerHeight - 20 ? -$el.value.offsetHeight - 10 : 10;

  gsap.set($el.value, {
    x: tickerPosition.x + xOffset,
    y: tickerPosition.y + yOffset,
  });
};

function hide() {
  if ($el.value) {
    gsap.to($el.value, {
      autoAlpha: 0,
    });
  }
}

function show() {
  if ($el.value) {
    gsap.to($el.value, {
      autoAlpha: 1,
    });
  }
}

watch(
  () => props.shouldFollow,
  () => {
    if (props.shouldFollow) show();
    else hide();
  }
);

watch(
  () => state.position.x,
  () => {
    if (props.hideMouse && $el.value) {
      gsap.to($el.value, { x: state.position.x });
    }
  }
);

watch(
  () => state.position.y,
  () => {
    if (props.hideMouse && $el.value) {
      gsap.to($el.value, { y: state.position.y });
    }
  }
);

watch(
  () => state.enabled,
  value => {
    isMouseEnabled.value = value;
  }
);

watch(
  () => props.shouldBeEnabled,
  value => {
    isMouseEnabled.value = value;
  }
);

watch(
  () => isMouseEnabled.value,
  value => {
    if (value && $el.value) {
      if (props.hideMouse) {
        document.body.style.cursor = 'none';
      }
      gsap.to($el.value, {
        width: 'auto',
        ease: 'expo.out',
        autoAlpha: 1,
        duration: 0.2,
      });
    } else {
      if (props.hideMouse) {
        document.body.style.cursor = 'auto';
      }
      if ($el.value) {
        gsap.to($el.value, {
          width: $el.value.offsetWidth,
          ease: 'expo.out',
          autoAlpha: 0,
        });
      }
    }
  }
);

onBeforeUnmount(() => {
  gsap.ticker.remove(onTicker);
});

onMounted(() => {
  if (!props.hideMouse) gsap.ticker.add(onTicker);
  if (props.shouldFollow) show();
});
</script>

<style src="./BaseMouseFollow.scss" lang="scss" scoped />
