<template><div></div></template>

<script lang="ts" setup>
import { useScriptTag } from '@vueuse/core';
import { nextTick } from 'process';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useFloatingElements } from '~/composables/useFloatingElements';
import { useHeader } from '~/composables/useHeader';
import { useLocale } from '~/composables/useLocale';
import { MARKET_TO_CHATBOT_ID_MAP } from '~/utils/chatbase';

const { localeMarket } = useLocale();
const { state: headerState } = useHeader();
const { state: floatingElementsState } = useFloatingElements();

const chatbotId = computed(() => MARKET_TO_CHATBOT_ID_MAP[localeMarket.value]);
const intervalId = ref<ReturnType<typeof setInterval>>();
const checksCount = ref(0);
let timeout: ReturnType<typeof setTimeout>;

declare global {
  interface Window {
    embeddedChatbotConfig: {
      chatbotId: string;
      domain: string;
    };
  }
}

function checkVisibility(initial: boolean = false) {
  if (checksCount.value > 10) {
    // If we can't find the elements after 10 tries, we stop checking
    clearInterval(intervalId.value);
    return;
  }

  const messageBubblesEl = document.querySelector('#chatbase-message-bubbles');
  const bubbleButtonEl = document.querySelector('#chatbase-bubble-button');
  const bubbleWindowEl = document.querySelector('#chatbase-bubble-window');

  if (!messageBubblesEl || !bubbleButtonEl || !bubbleWindowEl) {
    checksCount.value++;
    return;
  }

  if (initial) {
    floatingElementsState.chatbaseVisible = true;
    bubbleButtonEl.classList.add('is-initialized');
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      // Somtimes we need to wait for the button to be initialized before we can show it
      bubbleButtonEl.classList.add('is-initialized');
    }, 1000);
  }

  [messageBubblesEl, bubbleButtonEl, bubbleWindowEl].forEach(el => {
    if (headerState.menuOpen || floatingElementsState.hideAll) {
      el.classList.add('is-hidden');
    } else {
      el.classList.remove('is-hidden');
    }
  });

  clearInterval(intervalId.value);
}

function onScriptLoaded() {
  clearInterval(intervalId.value);
  intervalId.value = setInterval(() => {
    checkVisibility(true);
  }, 500);
}

const script = useScriptTag('https://www.chatbase.co/embed.min.js', onScriptLoaded, {
  manual: true,
  defer: true,
});

/**
 * Reload the Chatbot when the instance changes
 */
function reloadChatbaseScript(chatbotId: string) {
  window.embeddedChatbotConfig = {
    chatbotId,
    domain: 'www.chatbase.co',
  };
  script.unload();
  const chatbaseIds = ['chatbase-message-bubbles', 'chatbase-bubble-button', 'chatbase-bubble-window'];
  chatbaseIds.forEach(id => {
    const el = document.getElementById(id);
    if (el) {
      el.remove();
    }
  });
  nextTick(() => {
    script.load().catch(e => console.error(`Failed to reload Chatbase script: ${e}`));
  });
}

watch(chatbotId, () => {
  if (chatbotId.value) {
    reloadChatbaseScript(chatbotId.value);
  }
});

watch(
  () => [headerState.menuOpen, floatingElementsState.hideAll],
  () => {
    checkVisibility();
  }
);

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
  clearTimeout(timeout);
});

onMounted(() => {
  if (chatbotId.value && window.embeddedChatbotConfig) {
    reloadChatbaseScript(chatbotId.value);
    return;
  }
  if (chatbotId.value) {
    window.embeddedChatbotConfig = {
      chatbotId: chatbotId.value,
      domain: 'www.chatbase.co',
    };
    script.load().catch(e => console.error(`Failed to initially load Chatbase script: ${e}`));
  }
});
</script>

<style src="./BaseChatbase.scss" lang="scss" scoped />
