<template>
  <BaseModal :isOpen="isOpen" :isDark="isFirstBlokDark" :includeClose="true" animationType="fade" @close="close">
    <div ref="$modalContent" :key="isOpen.toString()" class="page-layer-wrapper">
      <div class="page-layer-top container-full">
        <button type="button" class="close-button" aria-label="Close" @click="close">
          <BaseIcon name="fi_x" :size="16" :color="isFirstBlokDark ? 'white' : 'black'" />
        </button>
      </div>
      <CorePageBlokModule
        v-for="(blokModule, index) in blok.content.body"
        :key="index"
        :blok="blokModule"
        :blokNext="blok.content.body?.[index + 1]"
        :index="index"
        :inModal="true"
        :settings="settings" />
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { ISbStoryData } from 'storyblok';
import { computed, onUnmounted, ref, watch } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseModal from '~/components/base/BaseModal.vue';
import { useLayer } from '~/composables/useLayer';
import { useScroll } from '~/composables/useScroll';
import type { CorePageLayerStoryblok, CoreSettingsStoryblok } from '~/types/storyblok-generated';
import CorePageBlokModule from '../CorePage/CorePageBlokModule.vue';

const props = defineProps<{
  blok: ISbStoryData<CorePageLayerStoryblok>;
  settings?: CoreSettingsStoryblok;
}>();

const { state } = useLayer();
const { setScroll, state: scrollState } = useScroll();

const $modalContent = ref<HTMLElement>();
const isOpen = ref(false);
const isFirstBlokDark = computed(() => props.blok.content.body?.[0]?.is_dark || false);

const close = () => {
  const { state } = useLayer();

  state.corePageLayerId = null;
};

let scrollStateDebounce: ReturnType<typeof setTimeout>;

function onManualScroll() {
  setScroll(($modalContent.value as HTMLElement).scrollTop, 0, true);
  scrollState.status = 'scroll';

  if (scrollStateDebounce) {
    clearTimeout(scrollStateDebounce);
  }
  scrollStateDebounce = setTimeout(() => {
    scrollState.status = 'none';
  }, 30);
}

onUnmounted(() => {
  clearTimeout(scrollStateDebounce);
  state.corePageLayerId = null;
});

watch(
  () => state.corePageLayerId,
  () => {
    if (state.corePageLayerId === props.blok.uuid) {
      isOpen.value = true;

      setTimeout(() => {
        if ($modalContent.value) {
          $modalContent.value.addEventListener('scroll', onManualScroll);
        }
      }, 10);
    } else {
      isOpen.value = false;

      if ($modalContent.value) {
        $modalContent.value.removeEventListener('scroll', onManualScroll);
      }
    }
  }
);
</script>

<style src="./CorePageLayer.scss" lang="scss" scoped />
