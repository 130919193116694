import { useRouter } from 'nuxt/app';
import type { MultilinkStoryblok } from '~/types/storyblok-generated';

export function useLinks() {
  function visitLink(link: MultilinkStoryblok) {
    if (link.linktype === 'url') {
      // Open in window
      window.open(link.url, '_blank');
    } else if (link.linktype === 'story') {
      // Push to router
      const router = useRouter();
      let url = link.story?.full_slug;
      if (!url?.startsWith('/')) {
        url = `/${url}`;
      }
      router.push({ path: url });
    }
  }

  return {
    visitLink,
  };
}
