import { reactive } from 'vue';

const state = reactive({
  enabled: false,
  position: { x: 0, y: 0 },
});

export function useMouseFollow() {
  const updatePosition = (position: { x: any; y: any }) => {
    state.position.x = position.x;
    state.position.y = position.y;
  };

  const onMouseMove = (e: MouseEvent) => {
    const target: HTMLElement = e.target as HTMLElement;

    // Get the bounding rectangle of target
    const rect = target.getBoundingClientRect();

    // Mouse position
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    updatePosition({ x, y });
  };

  return { state, onMouseMove, updatePosition };
}
