<template>
  <!--
    Usage:
    Simply add the data-cursor attribute to your mouse-sensitive element.

    Example:
    <div :data-cursor="JSON.stringify({ cursor: 'pointer', button: { theme: 'primary', text: 'Button Text' } })"></div>

    The string encoded data is typed as CursorData below.

  -->

  <!-- The base cursor container -->
  <div ref="$el" class="base-cursor">
    <!-- Component that follows the mouse -->
    <BaseMouseFollow :hideMouse="false" :instantFollow="!moduleState.parsedData">
      <!-- Conditionally render the button if button data is available -->
      <div v-if="moduleState.button" class="base-cursor__button">
        <UtilButton
          ref="$button"
          :blok="moduleState.button"
          :isMouseFollow="true"
          :isBackgroundDark="moduleState.isDark"
          :insetBorder="
            !moduleState.isDark && moduleState.button.theme !== 'primary'
              ? '#75787b'
              : moduleState.button.theme === 'primary'
                ? '#e1c9b7'
                : '#ffffff'
          " />
      </div>
    </BaseMouseFollow>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { useState } from 'nuxt/app';
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import BaseMouseFollow from '~/components/base/BaseMouseFollow.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useLinks } from '~/composables/useLinks';
import { useMouseFollow } from '~/composables/useMouseFollow';
import type { UtilButtonStoryblok } from '~/types/storyblok-generated';

interface CursorData {
  cursor?: string; // Cursor CSS property https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
  button?: UtilButtonStoryblok;
  labels?: string[]; // If set the animation will rotate through the labels
}

// Reactive state for the cursor module
const moduleState = reactive<{
  currentTarget: Element | undefined;
  button: UtilButtonStoryblok | undefined;
  isDown: boolean;
  cursor: string;
  currentLabel: string;
  parsedData: CursorData | undefined;
  isDark: boolean;
  modifiedParent: Element | undefined;
}>({
  currentTarget: undefined,
  modifiedParent: undefined,
  button: undefined,
  parsedData: undefined,
  isDown: false,
  cursor: '',
  currentLabel: '',
  isDark: false,
});

// Breakpoint state to check if on a tablet and not a touch device
const { state: stateBreakpoint, isTouch } = useBreakpoint();
const shouldFollow = computed(() => stateBreakpoint.isTablet && !isTouch.value);

const { updatePosition } = useMouseFollow();
const { visitLink } = useLinks();

// References to DOM elements
const $button = ref<InstanceType<typeof UtilButton>>();
const $el = ref<HTMLElement>();
let fadeIconTimeout: ReturnType<typeof setTimeout>;

// Set the cursor style on the HTML and target elements
function setCursor(parent: Element | undefined = undefined, data?: CursorData) {
  const cursor = !data ? 'auto' : (data.cursor ?? 'auto');
  gsap.set('html', { cursor: '' });

  // Other element mouseover
  if (!moduleState.button) {
    return;
  }

  if (parent) {
    gsap.set(parent, { cursor: cursor });
    moduleState.modifiedParent = parent;
  } else if (moduleState.modifiedParent) {
    const parentCursor = moduleState.parsedData?.cursor;
    gsap.set(moduleState.modifiedParent, { cursor: parentCursor });
  }
}

// State for the animation ticker
const tickerState = reactive<{
  mode: string;
  targetButton: UtilButtonStoryblok | undefined;
  progress: number;
  targetText: string;
  labelIndex: number;
}>({
  mode: 'in',
  targetButton: undefined,
  progress: 0,
  targetText: '',
  labelIndex: 0,
});

// Watch the progress of the ticker state and update the button text
watch(
  () => tickerState.progress,
  () => {
    const index = Math.round(tickerState.progress * tickerState.targetText.length);

    if (moduleState.button) {
      // Set the text based on animation progress
      moduleState.button.text = tickerState.targetText.slice(0, index);
    }
  }
);

// Check and animate the button if necessary
function checkButton() {
  if (tickerState.targetButton) {
    void animateButton(tickerState.targetButton);
  }
}

// Clear button data and reset the cursor
function clearButton(force: boolean = false) {
  if (tickerState.progress !== 0 && !force) {
    return;
  }
  setCursor();

  moduleState.parsedData = undefined;
  moduleState.currentTarget = undefined;
  tickerState.targetButton = undefined;
  tickerState.labelIndex = 0;
  tickerState.targetText = '';
  moduleState.button = undefined;
}

function checkLabels() {
  const parsedLabels: string[] = moduleState.parsedData?.labels ?? [];
  if (!parsedLabels || parsedLabels.length === 1) {
    return;
  }
  // Ticker labels
  void gsap
    .to(tickerState, {
      duration: 0.25,
      overwrite: true,
      progress: 0,
      delay: 5,
    })
    .then(() => {
      tickerState.labelIndex++;
      if (tickerState.labelIndex > parsedLabels.length - 1) {
        tickerState.labelIndex = 0;
      }
      tickerState.targetButton!.text = parsedLabels[tickerState.labelIndex];
      void animateButton(tickerState.targetButton ?? null);
    });
}

function updateButtonStyles() {
  if (!moduleState.button) {
    return;
  }

  if (moduleState.button.icon !== tickerState.targetButton?.icon) {
    moduleState.button.icon = tickerState.targetButton?.icon;
  }

  if (moduleState.button.theme !== tickerState.targetButton?.theme) {
    moduleState.button.theme = tickerState.targetButton!.theme;
  }
}

/**
 * Animate the button appearance
 */
async function animateButton(button: UtilButtonStoryblok | null) {
  if (!$el.value) {
    return;
  }

  if (button && !moduleState.button) {
    // Button already there. rewinding animation
    moduleState.button = button;
    moduleState.button.size = 'large';
    await nextTick();
  }

  const buttonEl = $el.value.querySelector('.base-cursor__button');

  if (button) {
    tickerState.targetButton = JSON.parse(JSON.stringify(button));
    tickerState.targetButton!.size = 'large';

    if (!moduleState.button) {
      // Fresh start
      moduleState.button = button;
      moduleState.button.text = '';
    }

    // Always large
    moduleState.button.size = 'large';

    if (tickerState.progress === 0) {
      // Button is tiny - changing styles
      tickerState.targetText = tickerState.targetButton?.text ?? '';
      updateButtonStyles();

      // No item is pending
      gsap.to(buttonEl, {
        duration: 0.25,
        autoAlpha: 1,
        overwrite: true,
        delay: 0,
      });

      const buttonInner = $el.value.querySelector('.base-cursor__button .util-button');
      gsap.to(buttonInner, {
        paddingLeft: '',
        paddingRight: '',
        duration: 0.2,
        overwrite: true,
      });

      clearTimeout(fadeIconTimeout);
      fadeIconTimeout = setTimeout(fadeIcon, 10);

      const tickerDuration = tickerState.targetText.length * 0.03;

      void gsap
        .to(tickerState, {
          overwrite: true,
          duration: tickerDuration,
          progress: 1,
          delay: 0,
        })
        .then(() => checkLabels());
    } else {
      // Rewind current item to create a fresh start
      gsap.to(tickerState, {
        duration: 0.25,
        overwrite: true,
        progress: 0,
        onComplete: checkButton,
      });
    }
  } else {
    hideButton();
  }
}

/**
 * Fade icon using timeout because it needs some time to render.
 */
function fadeIcon(opacity: number = 1) {
  if (!$el.value) {
    return;
  }
  const buttonIcon = $el.value.querySelector('.base-cursor__button .util-button__icon');

  if (buttonIcon) {
    gsap.to(buttonIcon, {
      autoAlpha: opacity,
      duration: 0.2,
      overwrite: true,
    });
  }
}

// Flag to prevent multiple hide calls
let isHiding = false;

// Hide the button with animation
function hideButton() {
  if (isHiding) {
    return;
  }
  isHiding = true;

  setCursor();

  const buttonEl = $el.value?.querySelector('.base-cursor__button');
  if (!buttonEl) {
    return;
  }

  // Remove padding
  const buttonInner = $el.value?.querySelector('.base-cursor__button .util-button');
  if (!buttonInner) {
    return;
  }
  gsap.to(buttonInner, {
    paddingLeft: 0,
    paddingRight: 0,
    duration: 0.25,
    delay: 0.2,
    overwrite: true,
  });

  // Hide icon
  fadeIcon(0);

  gsap.to(buttonEl, {
    autoAlpha: 0,
    duration: 0.25,
    delay: 0.25,
    overwrite: true,
    onComplete: clearButton,
  });

  gsap.to(tickerState, {
    duration: 0.25,
    progress: 0,
    overwrite: true,
  });
}

function onMouseOver(e: MouseEvent) {
  // Find the closest element with the data-cursor attribute
  const parent = (e.target! as HTMLElement).closest('[data-cursor]');

  let parsedData: CursorData | null = null;
  try {
    // Parse the cursor data
    parsedData = JSON.parse(parent?.getAttribute('data-cursor') ?? '{}') as CursorData;
    parsedData.labels = parsedData.labels?.map(label => label.trim()).filter(label => label.length > 0);

    if (!parent || !parsedData.button) {
      moduleState.currentTarget = undefined;
      setCursor();
      hideButton();
      return;
    }

    if (parent === moduleState.currentTarget && parent !== null) {
      return;
    }

    isHiding = false;
    moduleState.currentTarget = parent;

    if (parsedData.button) {
      // Hydrate cursor data with default values if necessary
      parsedData.button.component = 'util_button';
      parsedData.button.theme = parsedData.button.theme ? parsedData.button.theme : 'primary';
    } else {
      return;
    }

    moduleState.parsedData = parsedData;

    // Animate button appearance
    void animateButton(parsedData.button ? parsedData.button : null);
    setCursor(parent, parsedData);
  } catch (error) {
    console.error('Invalid JSON', error);
    // No valid json. Used for hiding
  }
}

function onMouseMove(e: MouseEvent) {
  updatePosition({ x: e.clientX, y: e.clientY });
}

function onMouseUp() {
  moduleState.isDown = false;
}

function onMouseDown() {
  moduleState.isDown = true;
}

function onClick(e: any) {
  if ((e?.target as HTMLElement).classList.contains('util-button')) {
    // Do nothing if a real button was clicked
    return;
  }

  if (tickerState.progress === 1) {
    // Button is visible -> Visit link if no custom click function is set
    const hasClick = $button.value?.onButtonClick() ?? false;
    if (!hasClick && tickerState.targetButton?.link) {
      visitLink(tickerState.targetButton?.link);
    }
  }
}

// Watch the isDown state to update the cursor style
watch(
  () => moduleState.isDown,
  () => {
    if (!moduleState.currentTarget) {
      return;
    }

    if (!moduleState.isDown || !moduleState.parsedData) {
      setCursor();
      return;
    }

    if (moduleState.parsedData.cursor === 'grab') {
      if (moduleState.isDown) {
        setCursor(moduleState.currentTarget, { cursor: 'grabbing' });
      } else {
        setCursor(moduleState.currentTarget, moduleState);
      }
    } else {
      setCursor();
    }
  }
);

function removeListeners() {
  document.documentElement.removeEventListener('click', onClick);
  document.documentElement.removeEventListener('mouseup', onMouseUp);
  document.documentElement.removeEventListener('mousedown', onMouseDown);
  document.documentElement.removeEventListener('mousemove', onMouseMove);
  document.documentElement.removeEventListener('mouseover', onMouseOver);
}

function addListeners() {
  removeListeners();
  document.documentElement.addEventListener('click', onClick);
  document.documentElement.addEventListener('mouseup', onMouseUp);
  document.documentElement.addEventListener('mousedown', onMouseDown);
  document.documentElement.addEventListener('mousemove', onMouseMove);
  document.documentElement.addEventListener('mouseover', onMouseOver);
}

// Watch shouldFollow value to add/remove listeners
const globalState = useState('cursor');

watch(
  () => shouldFollow.value,
  () => {
    if (shouldFollow.value) {
      removeListeners();
      addListeners();
    } else {
      removeListeners();
    }

    if (!shouldFollow.value) {
      clearButton(true);
    }
    globalState.value = shouldFollow.value;
  }
);

onUnmounted(() => {
  removeListeners();
});

onMounted(() => {
  if (shouldFollow.value) {
    addListeners();
  }
  globalState.value = shouldFollow.value;
});
</script>

<style src="./BaseCursor.scss" lang="scss" scoped />
